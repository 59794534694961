import React from "react"
import { Results } from "@usereactify/search"

import { withSearchResults } from "./withSearchResults"
import { SearchPagination } from "../Pagination/SearchPagination"
import { SearchListError } from "../List/SearchListError"
import { Loading } from "../List/Loading/Loading"
import { Empty } from "../List/Empty/Empty"
import { Card } from "../../Product/Card/Card"
import { Callout } from "../../Callout/Callout"

import { Wrapper } from "./SearchResultStyles"

export const SearchResults = withSearchResults(
  ({ collection }): JSX.Element => {
    const renderResultCardProduct = React.useCallback(
      props => {
        return <Card collection={collection} item={props?.product} {...props} />
      },
      [collection]
    )

    const renderResultCardCallout = React.useCallback(props => {
      return <Callout {...props} />
    }, [])

    const renderPagination = React.useCallback(props => {
      return <SearchPagination {...props} />
    }, [])

    return (
      <Wrapper>
        <Results
          renderLoading={() => <Loading />}
          renderNoResults={() => <Empty />}
          renderResultCardProduct={renderResultCardProduct}
          renderResultCardCallout={renderResultCardCallout}
          renderError={props => <SearchListError {...props} />}
          renderPagination={renderPagination}
        />
      </Wrapper>
    )
  }
)
