import React from "react"
import { useSettings } from "../../../hooks/useSettings"
import { useFilters } from "@usereactify/search"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const withFilters = Component => ({ name = "Filters", filterActive, setFilterActive, sizes }) => {
  const { swatches } = useSettings()
  const { filters } = useFilters()
  const { windowSize } = useWindowSize()

  const windowSizeInitialised = windowSize?.width
  const showMobile = windowSize?.width <= 1025

  const getSwatches = () =>
    swatches?.edges?.map(({ node }) => ({
      name: node?.name,
      slug: node?.slug?.current,
      linked: node?.linked,
      colour: node?.colour?.hex,
      image: node?.image?.asset?.url || false,
    })) || []

  const getSwatch = value => {
    const swatches = getSwatches().filter(
      swatch => swatch?.name?.toLowerCase() === value?.toLowerCase() || swatch?.linked?.map(item => item.toLowerCase()).includes(value?.toLowerCase())
    )
    return swatches?.length ? swatches[0] : false
  }

  Component.displayName = name
  return (
    <Component
      sizes={sizes}
      filterActive={filterActive}
      setFilterActive={setFilterActive}
      showMobile={windowSizeInitialised && showMobile}
      showDesktop={windowSizeInitialised && !showMobile}
      filters={filters}
      getSwatch={getSwatch}
    />
  )
}
