import React from "react"
import { Link } from "gatsby"
import { ReactifySearchProvider, ResultStateProvider, Sensors } from "@usereactify/search"

import { withCollection } from "./withCollection"
import { BreadCrumbs, Divider, FilterControl, FlexWrapper, IconWrapper, Panel, Stats, Wrapper } from "./CollectionStyles"
import { Sort } from "../Search/Sort/Sort"
import { SearchSelected } from "../Search/Selected/SearchSelected"
import { Filters } from "../Search/Filters/Filters"
import { SearchResults } from "../Search/Results/SearchResults"
import { Icon } from "../Icon/Icon"
import config from "../../../config.default"
import SeoBottomCopy from "./SeoBottomCopy"
import { Box } from "@material-ui/core"

export const Collection = withCollection(
  ({
    title,
    handle,
    parentCollection,
    filterActive,
    handleToggleFilter,
    setFilterActive,
    additionalFilterProductsText,
    additionalEditFiltersText,
    tiles,
    sizes,
    collection,
    count,
    buildStats,
    seoCopy,
    readMoreSeoTextButton,
    readMoreSeoTextButtonActive,
    readMoreSeoTextButtonColour,
  }): JSX.Element => (
    <>
      <ReactifySearchProvider
        mode="collection"
        collectionHandle={handle}
        shopifyPermanentDomain={config?.stores?.[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]?.searchDomain}
      >
        <Sensors />
        <Wrapper>
          <FlexWrapper>
            {parentCollection?.title ? (
              <BreadCrumbs>
                <Link to={parentCollection.url} title={parentCollection.title}>
                  {parentCollection.title.replace("‘", "'")}
                </Link>
                &nbsp;/&nbsp;{title.replace("‘", "'")}
              </BreadCrumbs>
            ) : null}
            <ResultStateProvider render={({ hits }) => <Stats>{buildStats(hits?.total - 1, hits?.hits?.length)}</Stats>} />
          </FlexWrapper>
          <Panel>
            <FilterControl onClick={handleToggleFilter}>
              {count > 0 ? <span>{`${additionalEditFiltersText} (${count})`}</span> : <span>{additionalFilterProductsText}</span>}
              <IconWrapper>
                <Icon name={filterActive ? "minus" : "plus"} width={12} height={12} />
              </IconWrapper>
            </FilterControl>
            <Sort handle={handle} />
          </Panel>
          {/*<SearchSelected sizes={sizes} />*/}
          <Divider />
          <Filters sizes={sizes} filterActive={filterActive} setFilterActive={setFilterActive} />
          <SearchResults collection={collection?.shopify} />
        </Wrapper>
      </ReactifySearchProvider>
      {seoCopy && (
        <SeoBottomCopy
          seoCopy={seoCopy}
          readMoreSeoTextButtonColour={readMoreSeoTextButtonColour}
          readMoreSeoTextButton={readMoreSeoTextButton}
          readMoreSeoTextButtonActive={readMoreSeoTextButtonActive}
        />
      )}
    </>
  )
)
