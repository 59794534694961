import React, { useEffect, useState, useRef } from "react"
import { StyledContainer } from "../Styled/Container"
import { SeoBottomCopyWrapper, SeoBottomCopyText, SeoBottomCopyButton } from "./CollectionStyles"
import { useCore } from "../../hooks/useCore"

type Props = {
  seoCopy?: any
  readMoreSeoTextButton?: string
  readMoreSeoTextButtonActive?: string
  readMoreSeoTextButtonColour?: string
}

const SeoBottomCopy: React.FC<Props> = ({ seoCopy, readMoreSeoTextButton, readMoreSeoTextButtonActive, readMoreSeoTextButtonColour }) => {
  const {
    helpers: { isDomReady },
  } = useCore()
  const [maxHeight, setMaxHeight] = useState<number>(0) // used to set the max height of the text
  const [showMore, toggleShowMore] = useState<boolean>(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const updateMaxHeight = () => {
    if (contentRef.current) {
      const element = contentRef.current.querySelector(".bottomCopy")
      if (element) {
        setMaxHeight(element.scrollHeight)
      }
    }
  }

  useEffect(() => {
    if (isDomReady) {
      updateMaxHeight()
    }
  }, [isDomReady, seoCopy])

  useEffect(() => {
    window.addEventListener("resize", updateMaxHeight)

    return () => {
      window.removeEventListener("resize", updateMaxHeight)
    }
  }, [])

  return (
    <SeoBottomCopyWrapper>
      <StyledContainer>
        <div ref={contentRef}>
          <SeoBottomCopyText className="bottomCopy" active={showMore} maxHeight={maxHeight}>
            {seoCopy}
          </SeoBottomCopyText>
        </div>
        <SeoBottomCopyButton buttonMargin={0} colour={readMoreSeoTextButtonColour} onClick={() => toggleShowMore(!showMore)}>
          <span>{showMore ? readMoreSeoTextButtonActive : readMoreSeoTextButton}</span>
        </SeoBottomCopyButton>
      </StyledContainer>
    </SeoBottomCopyWrapper>
  )
}

export default SeoBottomCopy
