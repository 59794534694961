import React from "react"

import { Outer, Inner } from "./SortStyles"
import { StyledSelectWrapper, StyledDropdownSelect, StyledSelectOption, StyledInputIconWrapper } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"
import { withSort } from "./withSort"

export const Sort = withSort(
  ({ sortOptions, sortOption, setSortOption }): JSX.Element => (
    <Outer>
      <Inner>
        <StyledSelectWrapper>
          <StyledDropdownSelect value={sortOption?.handle} onChange={e => setSortOption(e.target.value)} layout={"sortBy"}>
            {sortOptions?.map(option => (
              <StyledSelectOption key={option.id} value={option.handle}>
                {option.name}
              </StyledSelectOption>
            ))}
          </StyledDropdownSelect>
          <StyledInputIconWrapper>
            <Icon name={"chevron"} className={`mb-2`} width={"12"} height={"12"} />
          </StyledInputIconWrapper>
        </StyledSelectWrapper>
      </Inner>
    </Outer>
  )
)
