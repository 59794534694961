import React from "react"

import { Box } from "./Box/Box"
import { Swatch } from "./Swatch/Swatch"
import { Check } from "./Check/Check"

export const SearchFilter = ({ filter, item, getSwatch, sizes, handleChange }) => (
  <>
    {filter?.displayView === "box" && <Box sizes={sizes} item={item} handleChange={handleChange} />}
    {filter?.displayView === "check" || filter?.displayView === "range" ? <Check item={item} handleChange={handleChange} /> : null}
    {filter?.displayView === "swatch" && <Swatch getSwatch={getSwatch} item={item} handleChange={handleChange} />}
  </>
)
