import tw, { styled } from "twin.macro"

export const Label = tw.label`
  flex flex-row items-center w-full mb-1-6 md:mb-1-3 cursor-pointer
`

export const Img = styled.div<ImgProps>`
  ${tw`block relative w-3 h-3 md:w-2-2 md:h-2-2 mr-3 rounded-full border border-transparent`}
  :before {
    content: "";
    ${({ active }) =>
      active === "true"
        ? tw`absolute top-0-6 md:top-0-3 left-1-1 md:left-0-7 w-0-6 h-1-2 border-white border-t-0 border-r-2 border-b-2 border-l-0 transform rotate-45`
        : null}
  }
`

type ImgProps = {
  active: "true" | "false"
}
