import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  mlg:hidden
`

export const Inner = tw.div`
  w-full bg-beige-light pt-3-6 pb-6 px-2-4 min-h-full-vh
`

export const Title = tw.h2`
  font-bold text-14 leading-1.42 tracking-10 uppercase mb-3-2 text-center
`

export const Buttons = tw.div`
  grid grid-cols-2 gap-1-6 mt-3-2
`

export const Selected = styled.p`
  ${tw`text-14 leading-1.78 mr-5-6 text-grey`}
  white-space: nowrap;
`

export const FilterWrapper = styled.div`
  ${({ type }) => (type === "box" ? tw`grid grid-cols-3 gap-1 mb-1-6 md:mb-0` : tw`flex flex-wrap`)}
`
