import React from "react"
import { Filter } from "@usereactify/search"

import { withMobile } from "./withMobile"
import { Buttons, FilterWrapper, Inner, Outer, Title } from "./MobileStyles"
import { SearchFilter } from "../Filter/Filter"
import { Popup } from "../../../Popup/Popup"
import { StyledButton } from "../../../Styled/Button"
import { Accordion } from "../../../Accordion/Accordion"

export const Mobile = withMobile(
  ({
    filters,
    getSwatch,
    filterActive,
    setFilterActive,
    additionalFilterText,
    additionalClearFilterText,
    additionalApplyFiltersText,
    sizes,
  }): JSX.Element => {
    return (
      <Outer>
        <Popup active={filterActive} setActive={setFilterActive} width={"full"} mobileAttachTop>
          <Inner>
            <Title>{additionalFilterText}</Title>
            <div>
              {filters?.map(filter => (
                <Accordion key={filter.id} title={filter.name} layout={"filter"}>
                  <Filter
                    key={filter.id}
                    filter={filter}
                    renderFilterList={({ filter, filterListProps }) => {
                      return (
                        <FilterWrapper type={filter?.displayView}>
                          {filterListProps?.options
                            ?.filter(({ key }) => !filter.valuesExclude.includes(key))
                            ?.sort((a, b) => a.key - b.key)
                            ?.map((item: any) => {
                              return (
                                <SearchFilter
                                  key={item?.key}
                                  sizes={sizes}
                                  item={item}
                                  filter={filter}
                                  getSwatch={getSwatch}
                                  handleChange={filterListProps.handleChange}
                                />
                              )
                            })}
                        </FilterWrapper>
                      )
                    }}
                    renderFilterRange={({ filter, filterRangeProps }) => {
                      return (
                        <FilterWrapper type={filter?.displayView}>
                          {filterRangeProps?.options
                            ?.filter(({ key }) => !filter.valuesExclude.includes(key))
                            ?.sort((a: any, b: any) => a.key - b.key)
                            ?.map((item: any) => {
                              return (
                                <SearchFilter
                                  key={item?.key}
                                  sizes={sizes}
                                  item={item}
                                  filter={filter}
                                  getSwatch={getSwatch}
                                  handleChange={event => filterRangeProps.handleChange(event.target.value)}
                                />
                              )
                            })}
                        </FilterWrapper>
                      )
                    }}
                  />
                </Accordion>
              ))}
            </div>
            <Buttons>
              {/* <SearchComponents.SelectedFilters
                render={({ clearValues }) => (
                  <StyledButton onClick={clearValues} colour={"black-reverse"} size={"filter"} wide={"true"}>
                    {additionalClearFilterText}
                  </StyledButton>
                )}
              /> */}
              <StyledButton onClick={() => setFilterActive(false)} colour={"black"} size={"filter"} wide={"true"}>
                {additionalApplyFiltersText}
              </StyledButton>
            </Buttons>
          </Inner>
        </Popup>
      </Outer>
    )
  }
)
