import tw, { styled } from "twin.macro"

export const List = tw.div`
  w-full
`

export const ListItem = styled.div`
  ${tw`h-full`}
  ${({ full }) => (full === "true" ? tw`w-full` : null)}
`

export const Input = tw.input`
  hidden
`

export const KeyDesktop = tw.div`
  hidden lg:block
`

export const KeyMobile = tw.div`
  block lg:hidden
`
