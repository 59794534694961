import React from "react"
import { Filter } from "@usereactify/search"

import { SearchFilter } from "../Filter/Filter"
import { AccordionAnimation } from "../../../Accordion/AccordionAnimation"
import { Outer, Section, Inner, Title, FilterWrapper } from "./DesktopStyles"

export const Desktop = ({ filters, getSwatch, filterActive, sizes }): JSX.Element => {
  return (
    <Outer>
      <AccordionAnimation active={filterActive}>
        <Inner>
          {filters?.map(filter => (
            <Section key={filter.id}>
              <Title>
                {filter.handle.includes("size") && "AU"} {filter.name}
              </Title>
              <Filter
                key={filter.id}
                filter={filter}
                renderFilterList={({ filter, filterListProps }) => (
                  <FilterWrapper type={filter?.displayView}>
                    {filterListProps?.options
                      ?.filter(({ key }) => !filter.valuesExclude.includes(key))
                      ?.sort((a: any, b: any) => a.key - b.key)
                      ?.map((item: any) => {
                        return (
                          <SearchFilter
                            key={item?.key}
                            sizes={sizes}
                            item={item}
                            filter={filter}
                            getSwatch={getSwatch}
                            handleChange={filterListProps.handleChange}
                          />
                        )
                      })}
                  </FilterWrapper>
                )}
                renderFilterRange={({ filter, filterRangeProps }) => {
                  return (
                    <FilterWrapper type={filter?.displayView}>
                      {filterRangeProps?.options
                        ?.filter(({ key }) => !filter.valuesExclude.includes(key))
                        ?.sort((a: any, b: any) => a.key - b.key)
                        ?.map((item: any) => {
                          return (
                            <SearchFilter
                              key={item?.key}
                              sizes={sizes}
                              item={item}
                              filter={filter}
                              getSwatch={getSwatch}
                              handleChange={event => filterRangeProps.handleChange(event.target.value)}
                            />
                          )
                        })}
                    </FilterWrapper>
                  )
                }}
              />
            </Section>
          ))}
        </Inner>
      </AccordionAnimation>
    </Outer>
  )
}
