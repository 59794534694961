import React from "react"

import { Label, Img } from "./SwatchStyles"
import { List, ListItem, Input } from "../FilterStyles"

export const Swatch = ({ handleChange, item, getSwatch }): JSX.Element => {
  const { key, checked } = item
  const swatch = getSwatch(key.toLowerCase())

  return (
    <List>
      <ListItem full={"true"} key={key?.toString()}>
        <Label>
          <Img
            style={{
              background: swatch?.image ? `url("${swatch?.image}")` : swatch?.colour || "#fff",
            }}
            active={checked ? "true" : "false"}
          />
          <Input type="checkbox" value={key} checked={checked} onChange={handleChange} />
          {key}
        </Label>
      </ListItem>
    </List>
  )
}
