import tw, { styled } from "twin.macro"

export const Label = styled.label<LabelProps>`
  ${tw`flex justify-center items-center md:w-full h-full py-0-6 border rounded-5 text-center text-12 leading-1.78 md:mr-1-2 cursor-pointer`}
  ${({ active }) => (active === "true" ? tw`text-white bg-black` : tw`text-black bg-transparent`)}
`

type LabelProps = {
  active: "true" | "false"
}
