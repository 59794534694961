import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withMobile = Component => ({ name = "Mobile", ...props }) => {
  const { collectionTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_COLLECTION_MOBILE {
      collectionTemplateData: sanityTemplateCollection {
        additionalFilterText
        additionalClearFilterText
        additionalApplyFiltersText
      }
    }
  `)

  const { additionalFilterText, additionalClearFilterText, additionalApplyFiltersText } = collectionTemplateData || {}

  Component.displayName = name
  return (
    <Component
      {...props}
      additionalFilterText={additionalFilterText}
      additionalClearFilterText={additionalClearFilterText}
      additionalApplyFiltersText={additionalApplyFiltersText}
    />
  )
}
