import React from "react"

import { withFilters } from "./withFilters"
import { Mobile } from "./Mobile/Mobile"
import { Desktop } from "./Desktop/Desktop"

export const Filters = withFilters(
  ({ filters, getSwatch, filterActive, setFilterActive, showMobile, showDesktop, sizes }): JSX.Element => {
    return (
      <>
        {showMobile && <Mobile filters={filters} getSwatch={getSwatch} filterActive={filterActive} setFilterActive={setFilterActive} sizes={sizes} />}
        {showDesktop && <Desktop filters={filters} getSwatch={getSwatch} filterActive={filterActive} sizes={sizes} />}
      </>
    )
  }
)
