import React, { useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../hooks/useApp"
import { useRoutes } from "../../hooks/useRoutes"
import { useFilter } from "../../hooks/useFilter"

export const withCollection = Component => ({ name = "Collection", collection, template }) => {
  const { activeCollection, setActiveCollection, globalStateReducer } = useApp()
  const { getFilterCount } = useFilter()
  const { linkResolver, getUrlParameter } = useRoutes()

  const [filterCount, setFilterCount] = useState(getFilterCount())
  const [currentPage, updateCurrentPage] = useState(1)

  const { collectionTemplateData, sizes } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_COLLECTION {
      collectionTemplateData: sanityTemplateCollection {
        additionalFilterProductsText
        additionalEditFiltersText
      }
      sizes: sanityTemplateProduct {
        sizeConversions {
          mensSize
          womensSize
        }
      }
    }
  `)

  const { additionalFilterProductsText, additionalEditFiltersText } = collectionTemplateData || {}
  const { sizeConversions } = sizes || {}

  const [filterActive, setFilterActive] = useState(false)

  const handleToggleFilter = () => setFilterActive(prev => !prev)

  const [
    {
      resultsStats: { pageSize, numberOfResults },
    },
  ] = globalStateReducer

  const parentCollection = linkResolver(collection.parentCollection)

  useEffect(() => {
    if (collection?.id !== activeCollection?.id) {
      setActiveCollection(collection)
    }
  }, [collection, activeCollection?.id, setActiveCollection])

  useEffect(() => {
    if (getUrlParameter("page")) {
      updateCurrentPage(getUrlParameter("page"))
    }
  }, [getUrlParameter("page")])

  useEffect(() => {
    setFilterCount(getFilterCount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterActive])

  const buildStats = (total: number, length: number) => {
    const pageSize = 15
    const difference = total - length

    return total > 0
      ? `Showing ${currentPage == 1 ? 1 : difference} - ${currentPage * pageSize < total ? currentPage * pageSize : total} of ${total} products`
      : `No products`
  }

  const seoCopy = useMemo(() => collection?.seoBottomCopy, [collection?.seoBottomCopy])

  Component.displayName = name
  return (
    <Component
      title={collection.title}
      handle={collection.shopify.handle}
      collection={collection}
      parentCollection={parentCollection}
      filterActive={filterActive}
      handleToggleFilter={handleToggleFilter}
      setFilterActive={setFilterActive}
      additionalFilterProductsText={additionalFilterProductsText}
      additionalEditFiltersText={additionalEditFiltersText}
      tiles={collection.tiles}
      sizes={sizeConversions}
      count={filterCount}
      currentPage={currentPage}
      buildStats={buildStats}
      seoCopy={seoCopy}
      readMoreSeoTextButton={template?.readMoreSeoTextButton}
      readMoreSeoTextButtonActive={template?.readMoreSeoTextButtonActive}
      readMoreSeoTextButtonColour={template?.readMoreSeoTextButtonColour || "underlinedLinkRed"}
    />
  )
}
