import React from "react"

import { List, ListItem, Input } from "../FilterStyles"
import { Label } from "./CheckStyles"
import { Marker } from "../../../../Checkbox/CheckboxStyles"

export const Check = ({ handleChange, item }): JSX.Element => {
  const { key, checked } = item

  return (
    <List>
      <ListItem full={"true"} key={key?.toString()}>
        <Label>
          <Marker active={checked} />
          <Input type="checkbox" value={key} checked={checked} onChange={handleChange} />
          {key}
        </Label>
      </ListItem>
    </List>
  )
}
