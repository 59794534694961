import React from "react"

import { withEmpty } from "./withEmpty"
import { Wrapper, Title, Description } from "./EmptyStyles"
import { StyledButton } from "../../../Styled/Button"
import { Link } from "gatsby"

export const Empty = withEmpty(
  ({ title, description, link, isCollection }): JSX.Element => (
    <Wrapper>
      <Title large={isCollection ? "true" : "false"}>{title}</Title>
      <Description>{description}</Description>
      {link?.url ? (
        <StyledButton as={link?.url ? Link : null} to={link?.url} size={"tertiary"} colour={"black-reverse"}>
          {link?.title}
        </StyledButton>
      ) : null}
    </Wrapper>
  )
)
