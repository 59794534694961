export const useFilter = () => {
  const getFilterCount = () => {
    const search = typeof window !== "undefined" ? window.location.search : ""
    const filterOptions = search.split("&")
    const filterSum = filterOptions
      .filter(option => !option.includes("page") && !option.includes("load"))
      .map((option: string) => option?.split("%2C")?.length || 0)
      .reduce((a: number, b: number) => a + b, 0)
    return filterOptions[0] !== "" ? filterSum : 0
  }

  return { getFilterCount }
}
