import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"
import { css } from "@emotion/react"
import { UnderlinedButton } from "../Styled/Button"

export const Wrapper = tw.div`
  max-w-xl mx-auto md:px-3 px-2-4 pt-3-2 md:pt-3-2 pb-6 md:pb-12
`

export const Title = tw.div`
  font-bold uppercase text-35 leading-1.28 tracking-3 md:text-60 md:leading-none md:tracking-2 text-center mb-3 md:mb-4-8
`

export const BreadCrumbs = tw.div`
  text-14 leading-1.78 text-center
`

export const Panel = tw.div`
  grid grid-cols-1 mlg:grid-cols-2 items-center justify-between mb-3-2
`

export const Divider = tw.hr`hidden mlg:block`

export const FilterControl = styled.button<FilterControlProps>`
  ${tw`hidden mlg:flex border-transparent rounded px-1-6 py-1-2 mlg:py-0-8 text-16 leading-1.28 mlg:text-12 mlg:leading-2.08 tracking-10 justify-center mlg:justify-between items-center uppercase mlg:max-w-18 relative`}
  ${({ active }) => (active === "true" ? tw`bg-black text-white` : tw`bg-beige text-black mlg:hover:bg-beige-medium border-beige`)}
`

export const IconWrapper = tw.span`
  mlg:pl-1-2 absolute mlg:static right-1-6
`

export const FlexWrapper = tw.div`
  flex items-baseline justify-between mb-1-4
`

export const Stats = styled.p<StatsProps>`
  ${tw`text-center`}
`

export const SeoBottomCopyWrapper = tw.div`
  pb-6 md:pb-12
`

export const SeoBottomCopyText = styled(RichText)`
  ${tw`mb-1`}
  ${css`
    transition: max-height 0.3s ease-in-out;
    max-height: calc(1.6rem * 3);
    overflow: hidden;
  `}
    ${({ active, maxHeight }) =>
      active &&
      maxHeight &&
      css`
        max-height: ${maxHeight || 250}px;
      `}
`

export const SeoBottomCopyButton = styled(UnderlinedButton)`
  ${tw`font-bold uppercase tracking-10`}
  ${css`
    span {
      margin: 0;
    }
  `}
`

type StatsProps = {
  mobile?: "true" | "false"
}

type FilterControlProps = {
  active: "true" | "false"
}
