import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`max-w-40 mx-auto pt-3 md:pt-10 text-center col-span-3`

export const Title = styled.h3<TitleProps>`
  ${({ large }) =>
    large === "true" ? tw`px-1 text-20 leading-1.25 tracking-2` : tw`text-14 md:text-20 leading-1.42 md:leading-1.25 tracking-10 md:tracking-2`}
  ${tw`font-bold mb-3-2 md:mb-3 uppercase`}
`

export const Description = tw.p`
  text-14 leading-1.78 mb-2 md:mb-2-8
`

type TitleProps = {
  large: "true" | "false"
}
