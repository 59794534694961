import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = tw.div`
  hidden mlg:block
`

export const Inner = tw.div`
  pt-3 pb-4 grid grid-cols-6 border-b border-black border-opacity-25
`

export const Section = styled.section`
  ${tw`pr-1`}
  ${({ wide }) =>
    wide === "true"
      ? css`
          &:first-child {
            grid-column: 1 / 3;
          }
        `
      : null}
`

export const Title = tw.h3`
  text-14 leading-1.42 tracking-10 font-bold uppercase mb-3-6
`
export const FilterWrapper = styled.div`
  ${({ type }) => (type === "box" ? tw`grid grid-cols-3 gap-1 mb-1-6 md:mb-0` : tw`flex flex-wrap`)}
`
