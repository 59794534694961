import React from "react"

import { Label } from "./BoxStyles"
import { List, ListItem, Input, KeyDesktop, KeyMobile } from "../FilterStyles"

export const Box = ({ sizes, handleChange, item }): JSX.Element => {
  const { key, checked } = item

  const getSize = size => {
    if (!isNaN(size) && sizes) {
      const womensSize = sizes.find(sc => sc.mensSize == size)?.womensSize
      return womensSize ? `M ${size} | <br /> W ${womensSize}` : `M ${size}`
    }
    return size
  }

  const getSizeMobile = size => {
    if (!isNaN(size) && sizes) {
      const womensSize = sizes.find(sc => sc.mensSize == size)?.womensSize
      return womensSize ? `M ${size} <br /> W ${womensSize}` : `M ${size}`
    }
    return size
  }

  return (
    <>
      <List layout={"box"}>
        <ListItem key={key?.toString()}>
          <Label active={checked ? "true" : "false"}>
            <Input type="checkbox" value={key?.toString()} checked={checked} onChange={handleChange} />
            <KeyDesktop dangerouslySetInnerHTML={{ __html: getSize(key) }} />
            <KeyMobile dangerouslySetInnerHTML={{ __html: getSizeMobile(key) }} />
          </Label>
        </ListItem>
      </List>
    </>
  )
}
